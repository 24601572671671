.detail-product .slick-track {
    display: $__flex;
}
.detail-product .slick-slide {
    width: 25% !important;
    margin: 0 10px;
    overflow: $__hidden;
    &.slick-cloned {
        width: 25% !important;
        margin: 0 10px;
        overflow: $__hidden;
    }
    &.slick-active {
        width: 25% !important;
        margin: 0 10px;
        overflow: $__hidden;
    }
    img {
        width: 100%;
        border-radius: 10px;
    }
}
.detail-bigimg {
    position: $__relative;
    margin-bottom: 15px;
    z-index: 999;
    button {
        width: 50px;
        height: 50px;
        font-size: 0;
        background: $__white;
        border-radius: 50%;
        border: $__none;
        z-index: 9999;
        @include respond-below(custom991) {
            width: 35px;
            height: 35px;
        }
        &.slick-prev {
            @include position($__absolute,50%,null,null,30px);
            transform: translateY(-50%);
            &:hover {
                background: $__primarycolor !important;
                color: $__white;
                -webkit-transition: 500ms all ease;
                -o-transition: 500ms all ease;
                transition: 500ms all ease;
                -moz-transition: 500ms all ease;
                -ms-transition: 500ms all ease;
                &:before {
                    color: $__white!important;
                    }    

            }
            &:before {
                content: "\f060";
                display:$__inline__block;
                font-family: "Font Awesome 5 Free";
                font-size: $__font__size__15;
                font-weight: 900;  
                @include respond-below(custom991) {
                    font-size: $__font__size__13;
                }
            }
        }
        &.slick-next {
            @include position($__absolute,50%,30px,null,null);
            transform: translateY(-50%);
            &:hover {
                background: $__primarycolor !important;
                color: $__white;
                -webkit-transition: 500ms all ease;
                -o-transition: 500ms all ease;
                transition: 500ms all ease;
                -moz-transition: 500ms all ease;
                -ms-transition: 500ms all ease;
                &:before {
                    color: $__white!important;
                    }    
            }
            &:before {
                content: "\f061";
                display:$__inline__block;
                font-family: "Font Awesome 5 Free";
                font-size: $__font__size__15;
                font-weight: 900;  
                @include respond-below(custom991) {
                    font-size: $__font__size__13;
                }
            }
        }

     

    }
}
.car-details-slider, .details-car {
    &.owl-carousel {
        .owl-nav {
            display: $__flex;
            -webkit-display: $__flex;             
            @include position($__absolute,-80px,0,null,null);           
            @include respond-below(custom767) { 
                left: 0; 
                top: -100px
            }
            .owl-prev, .owl-next {
                width: 55px;
                height: 55px;
                @extend %flex-align-center;
                border: 1px solid $__light__gooses;
                box-shadow: 0px 4px 24px $__primarylight;
                background: $__white;
                border-radius: 50%;
                -webkit-transition: all 0.2s ease;
                -ms-transition: all 0.2s ease;
                transition: all 0.2s ease;
                @include respond-below(custom991) {  
                    width: 35px;
                    height: 35px;
                    font-size: 12px;
                }
                &:hover {
                    background: $__primarycolor;
                    color: $__white;
                    -webkit-transition: all 0.2s ease;
                    -ms-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                }
            }
            .owl-next {
                margin-left: 15px;
            }
        }
    }
    
}
