/*
Author       : TalabCar Inc.
Template Name: TalabCar - Bootstrap Template
Version      : 2.0
*/



// UTILS
@import "utils/variables";
@import "utils/mixins";

//  BASE
@import "base/reset";
@import "base/typography";
@import "base/colors"; 
@import "base/base";
@import "base/grid";

// COMPONENTS
@import "components/button";


// LAYOUT
@import "layout/content";
@import "layout/header";
@import "layout/footer";
@import "layout/breadcrumb";
@import "layout/select";
@import "layout/table";
@import "pages/common";
@import "pages/blog";
@import "pages/detail-page";
@import "pages/booking";
@import "pages/booking-calendar";
@import "pages/faq";


/*
// PAGES
@import "pages/faq";
@import "pages/pricing";
@import "pages/gallery";
@import "pages/our-team";
@import "pages/contact";
@import "pages/invoice";
@import "pages/blog";
@import "pages/testiminial";
@import "pages/about-us";
@import "pages/detail-page";
@import "pages/products";
@import "pages/users";
@import "pages/booking";
@import "pages/booking-calendar";
*/
