/*-----------------
    8. Breadcrumb
-----------------------*/

.breadcrumb-bar {
    background-image: url(../../img/bg/breadcrumb-bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 100px 0;
    position: relative;
    z-index: 1;
    @include respond-below(custom991) {
        padding: 50px 0;
    }
    @include respond-below(custom767) {
        padding: 40px 0;
    }

    &:before {
		content: "";
        background: url(../../img/breadcrumbleft.png) no-repeat;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 393px;
        height: 334px;
        background-size: cover;
        @include respond-below(custom991) {
            display: none;
        } 
	}
    &::after {
        content: "";
        background: linear-gradient(12deg, #111 30%, rgba(0, 0, 0, 0.2) 90%);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }
    .breadcrumb-title {
        font-weight: 700;
        color: #dbdbdb;
        font-size: $__font__size__36;
        @include margin-padding(0 0 15px, null);
        @include respond-below(custom991) {
            font-size: $__font__size__32;
        } 
    }
    .breadcrumb {
        justify-content: center;
        margin: 0;
        li {
            a {
                color: $__white;
            }
        }
        &.active {
            color: $__white;
        }
      }
    .breadcrumb-item+.breadcrumb-item {
        &::before {
            color: $__white;
        }
    }
    .breadcrumb-item.active {
        color: $__primarycolor;
    }
      
      
}