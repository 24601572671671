/*-----------------
    7. Footer
-----------------------*/

.footer {
	display: block;
	background: $__white;
	overflow: hidden;
	@include respond-below(custom991) {
		position: $__relative;
	}
	&::after {
		content: none;
	}
	&::before {
		//background: url('../../img/bg/footer-bg.png') no-repeat;
		width: 315px;
		height: 100%;
		bottom: 0;
	}

	.footer-top {
		position: relative;
		z-index: 99;
		@include margin-padding(0, 60px 0 0 0);
		@include respond-below(custom991) {  
			@include margin-padding(0, 40px 0 10px);
		}
		@include respond-below(custom767) {  
			@include margin-padding(0, 40px 0 0);
		}
	}
	.footer-widget {
		.footer-logo {
			margin-bottom: 1rem;
			img {
				width: auto;
				@include respond-below(custom767) {
					width: 30%;
				}	
			}
		}
		.footer-contact-info {
			margin-bottom: 38px;
			h6 {
				color: $__black;
				//text-transform: capitalize;
				span {
					color: $__primarycolor;
				}
			}
			.footer-address {
				.addr-info {
					a {
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						align-items: center;
						-webkit-box-align: center;
						-ms-flex-align: center;
						i {
							color: $__black-gray;
							margin-right: 5px;
						}
					}
				}
			}
		}	
		&.footer-menu {
			ul {
				list-style: $__none;
				@include margin-padding(0, 0);
			}
		}
		.footer-title {
			position: $__relative;
			font-size: $__font__size__20;
			font-weight: $__medium;
			color: $__black-gray;
			@include margin-padding(0 0 24px, null);
			&::before {
				content: none;
			}
		}
		@include respond-below(custom991) {  
			margin-bottom: 30px;
		}
		@include respond-below(custom767) {  
			margin-bottom: 25px;
		}
	}
	.footer-menu {
		ul {
			li {
				@include margin-padding(0 0 24px, null);
				&:last-child {
					margin-bottom: 0;
				}
				a {
					color: $__gray_light;
					font-size: $__font__size__14;
					@include transition(all 0.5s ease-in-out);
					&:hover {
						color: $__primarycolor;
						padding-left: 10px;
						@include transition(all 0.5s ease-in-out);
					}
				}
			}
		}
	}
	.footer-contact {
		h5 {
			font-weight: 700;
			font-size: $__font__size__24;
			color: $__white;
			max-width: 336px;
			margin-bottom: 20px;
		}
		h6 {
			font-size: $__font__size__16;
			color: $__blue__light;
			margin-bottom: 15px;
			line-height: 20px;
		}
		&-info {
			.footer-address {
				@extend %display-flex;
				margin-bottom: 3px;
				&:last-child {
					margin-bottom: 0;
				}	
				span {
					margin-right: 20px;
					width: 50px;
					height: 50px;
					background: $__warningcolor;
					color: $__white;
					font-size: $__font__size__20;
					border-radius: 4px;
					@extend %flex-align-center;
					flex-shrink: 0;
				}
				.addr-info {
					@extend %display-flex-center;
					a {	
						font-weight: 400;
						font-size: $__font__size__14;
						color: $__gray_light;
					}
				}
			}
		}	
		
	}
	.footer-bottom {
		background: $__white;
		position: $__relative;
    	z-index: 99;
		@include margin-padding(0, 0);
		@include respond-below(custom767) {  
			@include margin-padding(0, 15px 0);
		}
		.copyright {
			border-top: 1px solid rgb(226, 232, 240);
			padding: 20px 0;
			@include respond-below(custom767) {
				text-align: $__center;
			}
			.copyright-text {
				@include respond-below(custom767) {
					@include margin-padding(0 0 20px, null);
				}
				@include respond-below(custom991) {
					text-align: center;
					margin-bottom: 15px;
				}

				p {
					color: $__gray_light;	
					font-size: $__font__size__12;	
					font-weight: $__medium;			
				}
			}
			.vistors-details {
				ul {
					justify-content: end;
					-webkit-justify-content: end;
					@include respond-below(custom767) { 
						justify-content: center;
					}
					li {
						@include margin-padding(0, 0 8px 0);
						&:last-child {
							padding-right: 0;
						}
						&:first-child {
							padding-left: 0;
						}
						a {
							background: $__light__gooses;
							width: 46px;
							height: 30px;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 5px;
						}
					}
				}
			}
		}
	}
	.footer-list {
		& > ul {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			align-items: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			justify-content: end;
			-webkit-justify-content: end;
			-ms-flex-pack: end;
			@include respond-below(custom991) {
				justify-content: center;
				-webkit-justify-content: center;
				-ms-flex-pack: center;
			}
			@include respond-below(custom575) {
				flex-wrap: wrap;
			}
			& > li {
				margin-right: 15px;
				@include respond-below(custom575) {
					margin-top: 10px;
				}
				&:last-child {
					margin-right: 0;
				}
				&.lang-nav {
					margin-right: 24px;
					a {
						&.dropdown-toggle {
							i {
								font-size: $__font__size__20;
								margin-right: 6px;
								color: $__gray_light;
							}
						}
					}
				}
				&.country-flag {
					margin-top: 0px;
					img {
						width: 20px;
						height: 20px;
						margin-right: 9px;
						border-radius: 50%;
					}
					a {
						color: $__black-gray;
						font-size: $__font__size__14;
						padding: 9px 8px;
						&.dropdown-toggle {
							background: $__white;
							border: 1px solid $__light__gooses;
							border-radius: 5px;
							display: inline-flex;
							align-items: center;
						}
					}
					.dropdown-menu {
						padding: 10px;
						a {
							padding: 5px 10px;
							border-radius: 10px;
							color: $__black-gray;
							&:hover {
								background: #F8FAFC;
							}
						}
					}
				}
			}
		}
		.social-icon {
			li {
				display: inline-block;
				margin-right: 10px;
				a {
					width: 24px;
					height: 24px;
					@include rounded(50%);
					font-size: $__font__size__12;
					color: $__white;
					background: $__black-gray;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					align-items: center;
					-webkit-box-align: center;
					-ms-flex-align: center;
					justify-content: center;
					-webkit-justify-content: center;
					-ms-flex-pack: center;
					.fa-facebook, .fa-instagram, .fa-behance, .fa-twitter, .fa-linkedin {
						background:  $__black-gray;
					}
					&:hover {
						background: $__primarycolor;
						.fa-facebook, .fa-instagram, .fa-behance, .fa-twitter, .fa-linkedin {
							background:  $__primarycolor;
						}
					}
				}
			}
		}
	}
}
@-webkit-keyframes toTopFromBottom {
	49% {
		-webkit-transform: translateY(-100%);
	}
	50% {
		opacity: 0;
		-webkit-transform: translateY(100%);
	}
	51% {
		opacity: 1;
	}
}
@-moz-keyframes toTopFromBottom {
	49% {
		-moz-transform: translateY(-100%);
	}
	50% {
		opacity: 0;
		-moz-transform: translateY(100%);
	}
	51% {
		opacity: 1;
	}
}
@keyframes toTopFromBottom {
	49% {
		transform: translateY(-100%);
	}
	50% {
		opacity: 0;
		transform: translateY(100%);
	}
	51% {
		opacity: 1;
	}
}

.footer-social-widget {
	@include respond-above(custom991) {
		position: absolute;
		bottom: 8px;
	}
	.nav-social {
		@extend %display-flex;
		@extend %jusflex-start;
		li {
			margin-right: 10px;
			&:last-child {
				margin-right: 0;
			}
			a {
				&:hover {
					color: $__black;
				}
			}
		}
	}
}
.hi-icon {
    @extend %flex-align-center;
    font-size: 0px;
    cursor: $__pointer;
    text-align: $__center;
    position: $__relative;
    z-index: 1;
    overflow: $__hidden;
    color: $__goose-gray;
    background: $__blue__light;
    border-radius: 50px;
    min-width: 36px;
    height: 36px;
    -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    @include respond-below(custom767) {
        min-width: 36px;
        height: 36px;
    }
    &:after {
        pointer-events: $__none;
        position: $__absolute;
        width: $__full__width;
        height: $__full__height;
        border-radius: 0;
        content: '';
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        display: $__none;
    }
    &:before {
        speak: $__none;
        font-size: $__font__size__18;
        line-height: 20px;
        font-style: $__normal;
        font-weight: $__normal;
        font-variant: $__normal;
        text-transform: $__none;
        display: $__block;
        -webkit-font-smoothing: antialiased;
        @include respond-below(custom767) {
            font-size: $__font__size__15;
        }
    }
    &:hover {
        color: $__goose-gray;
        &:before {
            -webkit-animation: toTopFromBottom 0.5s forwards;
            -moz-animation: toTopFromBottom 0.5s forwards;
            animation: toTopFromBottom 0.5s forwards;
        }
    }
}
.fi-icon {
    @extend %flex-align-center;
    font-size: 0px;
    cursor: $__pointer;
    text-align: $__center;
    position: $__relative;
    z-index: 1;
    overflow: $__hidden;
    border-radius: 50px;
    min-width: 25px;
    height: 25px;
	background-color: $__primarycolor;
    -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    @include respond-below(custom767) {
        min-width: 30px;
        height: 30px;
    }
    &:after {
        pointer-events: $__none;
        position: $__absolute;
        width: $__full__width;
        height: $__full__height;
        border-radius: 0;
        content: '';
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        display: $__none;
    }
    &:before {
        speak: $__none;
        font-size: $__font__size__12;
        line-height: 20px;
        font-style: $__normal;
        font-weight: $__normal;
        font-variant: $__normal;
        text-transform: $__none;
        display: $__block;
        -webkit-font-smoothing: antialiased;		
		color: $__white;
        @include respond-below(custom767) {
            font-size: $__font__size__15;
        }
    }
    &:hover {
        color: $__goose-gray;
        &:before {
            -webkit-animation: toTopFromBottom 0.5s forwards;
            -moz-animation: toTopFromBottom 0.5s forwards;
            animation: toTopFromBottom 0.5s forwards;
        }
    }
}