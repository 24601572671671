/*-----------------
    5. Buttons
-----------------------*/

.btn {
    position: $__relative;
    text-transform: initial;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        transition: all 0.5s;
    }
}

.btn-primary {
    background-color: $__primarycolor;
    border: 1px solid $__primarycolor;
    box-shadow: inset 0 0 0 0 $__white;
    font-weight: $__medium;
    font-size: $__font__size__16;
    color: $__white;
    @include rounded(50px);
    @include margin-padding(null, 13px 38px);
    @extend %animation-effect;

    @include respond-below(custom1199) {
      padding: 7px 15px;
    }
    @include respond-below(custom991) {
        @include margin-padding(null, 8px 12px);
        font-size: $__font__size__14;
      }

    i {
        @include margin-padding(0 5px 0 0, null);
      }
    &:hover {
        background-color: $__white;
        border: 1px solid $__primarycolor;
        color: $__primarycolor;
        @extend %animation-effect;
      }
}


.btn-outline-primary {
    background-color: $__white;
    border: 1px solid $__primarycolor;
    box-shadow: inset 0 0 0 0 $__white;
    font-weight: $__medium;
    font-size: $__font__size__16;
    color: $__primarycolor;
    @include rounded(50px);
    @include margin-padding(null, 13px 38px);
    @extend %animation-effect;

    @include respond-below(custom1199) {
      padding: 7px 15px;
    }
    @include respond-below(custom991) {
        @include margin-padding(null, 8px 12px);
        font-size: $__font__size__14;
      }

    i {
        @include margin-padding(0 5px 0 0, null);
      }
    &:hover {
        background-color: $__primarycolor;
        border: 1px solid $__primarycolor;
        color: $__white;
        @extend %animation-effect;
      }
}

.btn-secondary {
    background-color: $__goose-gray;
    border: 1px solid $__white;
    box-shadow: inset 0 0 0 0 $__white;
    font-weight: $__medium;
    font-size: $__font__size__16;
    color: $__white;
    @include rounded(50px);
    @include margin-padding(null, 13px 38px);
    @extend %animation-effect;

    @include respond-below(custom1199) {
      padding: 7px 15px;
    }
    @include respond-below(custom991) {
        @include margin-padding(null, 8px 12px);
        font-size: $__font__size__14;
      }

    i {
        @include margin-padding(0 5px 0 0, null);
      }
    &:hover {
        background-color: $__white;
        border: 1px solid $__goose-gray;
        color: $__goose-gray;
        @extend %animation-effect;
      }
}


.btn-outline-secondary {
    background-color: $__white;
    border: 1px solid $__goose-gray;
    box-shadow: inset 0 0 0 0 $__white;
    font-weight: $__medium;
    font-size: $__font__size__16;
    color: $__goose-gray;
    @include rounded(50px);
    @include margin-padding(null, 13px 38px);
    @extend %animation-effect;

    @include respond-below(custom1199) {
      padding: 7px 15px;
    }
    @include respond-below(custom991) {
        @include margin-padding(null, 8px 12px);
        font-size: $__font__size__14;
      }

    i {
        @include margin-padding(0 5px 0 0, null);
      }
    &:hover {
        background-color: $__primarycolor;
        border: 1px solid $__primarycolor;
        color: $__white;
        @extend %animation-effect;
      }
}


.btn-gray {
    background-color: $__white__smoke;
    border: 1px solid $__white__smoke;
    box-shadow: inset 0 0 0 0 $__white;
    &:hover {
        background-color: $__white;
        border-color: $__white__smoke;
        color: $__gray15;
        //box-shadow: inset 0 50px 0 0 ;
    }
}
.btn-gray-outline {
    background-color: $__white;
    border: 1px solid $__white__smoke;
    box-shadow: inset 0 0 0 0 $__white;
    &:hover {
        background-color: $__white__smoke;
        border-color: $__white;
        color: $__gray15;
        //box-shadow: inset 0 50px 0 0 ;
    }
}
.fa-facebook {
    background: #3080F8;
    color: $__white;
}  
.fa-twitter {
    background: #35B6FF;
    color: $__white;
} 
.fa-instagram {
    background: #FF2D20;
    color: $__white;
}
.fa-behance {
    background: #0066FF;
    color: $__white;
}
.fa-linkedin {
    background: #0066FF;
    color: $__white;
}
.btn-primary {
    &.btn {
        &:first-child {
            &:active {
                background: $__primarycolor;
                border-color: $__primarycolor;
            }
        }
    }
}
.btn-check:focus+.btn-primary, 
.btn-primary:focus {
    box-shadow: $__none !important;
}
.btn{
    &:focus{
        box-shadow: $__none !important; 
    }
}
